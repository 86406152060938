<template>
    <a-layout>
        <a-layout-header style="background: #fff; padding: 0"/>
        <a-layout-content style="margin: 0 16px">
            <a-breadcrumb style="margin: 16px 0">
                <a-breadcrumb-item>首页</a-breadcrumb-item>
                <a-breadcrumb-item>权限表</a-breadcrumb-item>
            </a-breadcrumb>
            <div class="toolsbar">
                <div class="left_button">
                    <a-button type="primary" @click="addGroup()">
                        新增权限组
                    </a-button>
                    <a-button type="primary" :loading="loading" @click="start">
                        刷新
                    </a-button>
                </div>
            </div>

            <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
                <a-card :title="item.name+'[id:'+item.id+']'" v-for="(item,index) in data" :key="index" style="margin-bottom: 10px;">
                    <a slot="extra" href="###" style="margin-right: 20px" @click="addPermissions(item.id)">新增权限</a>
                    <a slot="extra" href="###" style="margin-right: 20px" @click="editGroup(item.id,item.name)">编辑权限组</a>
                    <a slot="extra" href="###" @click="delGroupOk(item.id)">剔除权限组</a>
                    <a-list item-layout="horizontal" :data-source="item.rules">
                        <a-list-item slot="renderItem" slot-scope="items, index" style="margin-left: 100px">
                            <div slot="extra">
                                <a href="###" @click="editpermissions(items.id,items.name,item.id)" style="margin-right: 20px">编辑</a>
                                <a href="###" @click="delpermissionsOk(items.id)">剔除</a>
                            </div>
                            {{items.name}}
                        </a-list-item>
                    </a-list>
                </a-card>
               <!--                <a-table-->
<!--                    bordered-->
<!--                    :columns="columns"-->
<!--                    :dataSource="data"-->
<!--                    :loading="loading"-->
<!--                    @change="onChange"-->
<!--                    rowKey="permissions_id"-->
<!--                >-->

<!--                    <template slot="type" slot-scope="tags" style="text-align: center">-->
<!--                        <a-tag v-if="tags === 1" color="blue">-->
<!--                            访问权限-->
<!--                        </a-tag>-->
<!--                        <a-tag v-if="tags === 2" color="green">-->
<!--                            操作权限-->
<!--                        </a-tag>-->
<!--                    </template>-->
<!--                    <template slot="type2" slot-scope="tags" style="text-align: center">-->
<!--                        <a-tag v-if="tags === 1" color="blue">-->
<!--                            父菜单-->
<!--                        </a-tag>-->
<!--                        <a-tag v-if="tags === 2" color="green">-->
<!--                            子菜单-->
<!--                        </a-tag>-->
<!--                        <a-tag v-if="tags === 3" color="green">-->
<!--                            独立菜单-->
<!--                        </a-tag>-->
<!--                    </template>-->
<!--                    <template slot="status" slot-scope="tags" style="text-align: center">-->
<!--                        <a-tag v-if="tags === 1" color="green">-->
<!--                            使用中-->
<!--                        </a-tag>-->
<!--                        <a-tag v-if="tags === 2" color="red">-->
<!--                            禁止-->
<!--                        </a-tag>-->


<!--                    </template>-->

<!--                    <template slot="operation" slot-scope="text, index">-->
<!--                        <div class="editable-row-operations">-->
<!--                            <span>-->
<!--&lt;!&ndash;                                v-if="text.status==1"&ndash;&gt;-->
<!--                                <a @click="showModal(index)">编辑</a>-->
<!--                            </span>-->
<!--                            <span>-->
<!--&lt;!&ndash;                                v-if="text.status==1"&ndash;&gt;-->
<!--                                <a @click="delModal(index)">剔除</a>-->
<!--                            </span>-->
<!--                        </div>-->
<!--                    </template>-->
<!--                </a-table>-->
            </div>
        </a-layout-content>
        <a-layout-footer style="text-align: center"></a-layout-footer>

        <a-modal title="新增权限组" v-model="popaddGroup" @ok="addGroupok" width="45%">
            <a-form-item label="权限组名字" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                <a-input v-model="a_g_name"/>
            </a-form-item>
        </a-modal>
        <a-modal title="新增权限" v-model="popaddPermissions" @ok="addPermissionsok" width="45%">
            <a-form-item label="权限名字" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                <a-input v-model="a_p_name"/>
            </a-form-item>
        </a-modal>
        <a-modal title="编辑权限组信息" v-model="popeditGroup" @ok="edit_G_Ok" width="55%">
            <a-form-item label="权限组名字" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                <a-input v-model="edit_g_name"/>
            </a-form-item>
        </a-modal>
        <a-modal title="编辑权限" v-model="popeditPermissions" @ok="edit_P_Ok" width="45%">
            <a-form-item label="权限名字" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                <a-input v-model="edit_p_name"/>
            </a-form-item>
        </a-modal>
    </a-layout>
</template>

<script>
    const columns = [
        {
            title: "权限id",
            dataIndex: "id",
            width: "5%",
            scopedSlots: {customRender: "id"}
        },
        {
            title: "权限名字",
            dataIndex: "name",
            width: "15%",
            scopedSlots: {customRender: "name"}
        },
        {
            title: "上级id",
            dataIndex: "f_permissions_id",
            width: "5%",
            scopedSlots: {customRender: "f_permissions_id"}
        },

        {
            title: "权限状态",
            dataIndex: "type",
            scopedSlots: {customRender: "type"}
        },
        {
            title: "权限范围",
            dataIndex: "type2",
            scopedSlots: {customRender: "type2"}
        },
        {
            title: "地址",
            dataIndex: "do_value",
            scopedSlots: {customRender: "do_value"}
        },

        {
            title: "状态",
            dataIndex: "status",
            scopedSlots: {customRender: "status"}
        },
        {
            title: "操作",
            width: "10%",
            // key: "operation",
            scopedSlots: {customRender: "operation"}
        }
    ];

    import {Modal} from "ant-design-vue";
    import Storage from "../common/storage";
    // import Crypt from "../common/crypt";
    // import * as Util from "../common/util";

    export default {

        name: "Userlist",
        components: {},
        data() {
            return {
                columns,
                data: [],

                editingKey: '',
                pagination: {
                    pageSize: 15
                },
                page: 1,
                search_text: "",
                loading: false,
                popaddGroup: false,
                popaddPermissions: false,
                popeditGroup: false,
                popeditPermissions: false,

                a_g_name: "",
                edit_g_name: "",
                edit_g_id: "",
                a_p_name: "",
                edit_p_id: "",
                edit_p_name: "",
                a_rule_category_id: "",
                edit_rule_category_id: "",

            };
        },
        created() {
            // 获取第一页内容
            this.doSearch("1");
        },
        methods: {
            start() {
                this.loading = true;
                this.data = [];
                // ajax request after empty completing
                setTimeout(() => {
                    this.loading = false;
                    this.selectedRowKeys = [];
                }, 1000);
                this.doSearch(this.page, "1");
            },
            async onSearch(text) {
                this.data = [];
                this.search_text = text;
                this.doSearch(1, -1, this.search_text);

            },

            async onChange(pagination) {
                await this.doSearch(pagination.current, "1");
            },

            showModal(item) {
                this.edit_pop.permissions_id = item.permissions_id;
                this.edit_pop.name = item.name;
                this.edit_pop.f_permissions_id = item.f_permissions_id;
                this.edit_pop.type = item.type;
                this.edit_pop.type2 = item.type2;
                this.edit_pop.do_value = item.do_value;
                this.popinfos = true;
            },

            delModal(delid) {
                this.del_pop.del_id = delid.permissions_id;
                this.del_pop.del_name = delid.name;
                this.delOk(delid);
            },

            addGroup() {
                this.a_g_name=""
                this.popaddGroup = true;
            },

            addPermissions(id) {
                this.a_p_name=""
                this.a_rule_category_id = id;
                console.log(id)
                this.popaddPermissions = true;
            },

            editpermissions(id,name,gid) {
                this.edit_p_name = name;
                this.edit_p_id = id;
                this.edit_g_id = gid;
                console.log(id)
                this.popeditPermissions = true;
            },
            editGroup(id,name) {
                this.edit_g_name = name;
                this.edit_g_id = id;
                console.log(id)
                this.popeditGroup = true;
            },

            async addGroupok() {
                this.popaddGroup = false;

                let now = Date.now();
                let result = await this.$post("/api/admin/ruleCategory/create", {
                    token: Storage.token,
                    uid: Storage.uid,
                    name: this.a_g_name,
                    t: now,
                    // sign: Crypt.sign([
                    //     Storage.token,
                    //     this.a_account,
                    //     this.a_password,
                    //     this.a_level,
                    //     this.a_notes,
                    //     this.$config.secret_key,
                    //     now
                    // ])
                });

                if (result.status == true) {
                    this.data = []
                    await this.doSearch(this.page);
                    Modal.info({
                        title: "创建成功"
                    });
                }
                if(result.status === false){
                    // that.data = []
                    // await this.doSearch(this.page, "1", "", this.estatedefault, "");
                    Modal.info({
                        title: result.msg
                    });
                }
            },
            async edit_G_Ok() {
                this.popeditGroup = false;

                let now = Date.now();
                let result = await this.$post("/api/admin/ruleCategory/update", {
                    token: Storage.token,
                    uid: Storage.uid,
                    name: this.edit_g_name,
                    id: this.edit_g_id,
                    t: now,
                    // sign: Crypt.sign([
                    //     Storage.token,
                    //     this.a_account,
                    //     this.a_password,
                    //     this.a_level,
                    //     this.a_notes,
                    //     this.$config.secret_key,
                    //     now
                    // ])
                });

                if (result.status == true) {
                    this.data = []
                    await this.doSearch(this.page);
                    Modal.info({
                        title: "更新成功"
                    });
                }
                if(result.status === false){
                    // that.data = []
                    // await this.doSearch(this.page, "1", "", this.estatedefault, "");
                    Modal.info({
                        title: result.msg
                    });
                }
            },
            async edit_P_Ok() {
                this.popeditPermissions = false;

                let now = Date.now();
                let result = await this.$post("/api/admin/rules/update", {
                    token: Storage.token,
                    uid: Storage.uid,
                    rule_id: this.edit_p_id,
                    save_value:{
                        name: this.edit_p_name,
                        rule_category_id: this.edit_g_id,
                    },

                    t: now,
                    // sign: Crypt.sign([
                    //     Storage.token,
                    //     this.a_account,
                    //     this.a_password,
                    //     this.a_level,
                    //     this.a_notes,
                    //     this.$config.secret_key,
                    //     now
                    // ])
                });

                if (result.status == true) {
                    this.data = []
                    await this.doSearch(this.page);
                    Modal.info({
                        title: "创建成功"
                    });
                }
                if(result.status === false){
                    // that.data = []
                    // await this.doSearch(this.page, "1", "", this.estatedefault, "");
                    Modal.info({
                        title: result.msg
                    });
                }
            },
            async addPermissionsok() {
                this.popaddPermissions = false;

                let now = Date.now();
                let result = await this.$post("/api/admin/rules/create", {
                    token: Storage.token,
                    uid: Storage.uid,
                    save_value:{
                        name: this.a_p_name,
                        rule_category_id: this.a_rule_category_id,
                    },
                    t: now,
                    // sign: Crypt.sign([
                    //     Storage.token,
                    //     this.a_account,
                    //     this.a_password,
                    //     this.a_level,
                    //     this.a_notes,
                    //     this.$config.secret_key,
                    //     now
                    // ])
                });

                if (result.status == true) {
                    this.data = []
                    await this.doSearch(this.page);
                    Modal.info({
                        title: "创建成功"
                    });
                }
                if(result.status === false){
                    // that.data = []
                    // await this.doSearch(this.page, "1", "", this.estatedefault, "");
                    Modal.info({
                        title: result.msg
                    });
                }
            },
            async handleOk() {
                this.popinfos = false;

                let data = {
                    f_permissions_id: this.edit_pop.f_permissions_id,
                    name: this.edit_pop.name,
                    type: this.edit_pop.type,
                    type2: this.edit_pop.type2,
                    do_value: this.edit_pop.do_value,
                };
                let now = Date.now();
                let result = await this.$post("/api/admin/editPermissions", {// eslint-disable-line no-unused-vars
                    permissions_id: this.edit_pop.permissions_id,
                    token: Storage.token,
                    uid: Storage.uid,
                    save_value: data,
                    // notes: this.edit_pop.notes,
                    t: now,
                    // sign: Crypt.sign([
                    //     Storage.token,
                    //     this.edit_pop.id,
                    //     JSON.stringify(data),
                    //     this.edit_pop.notes,
                    //     this.$config.secret_key,
                    //     now
                    // ])
                });
                await this.doSearch(this.page);
                // this.start();
            },

            async delpermissionsOk(id) {
                var that = this;
                Modal.confirm({
                    title: "警告！",
                    content: "是否确认剔除该权限",
                    onOk: async function () {
                        console.log("执行完毕！");
                        let result = await that.$post("/api/admin/rules/delete", {
                            rule_id: id,
                            token: Storage.token,
                            uid: Storage.uid,
                            // notes: this.edit_pop.notes,
                            t: Date.now(),
                            // sign: Crypt.sign([
                            //     Storage.token,
                            //     this.edit_pop.id,
                            //     JSON.stringify(data),
                            //     this.edit_pop.notes,
                            //     this.$config.secret_key,
                            //     now
                            // ])
                        });
                        console.log(result);
                        if (result.status == true) {
                            console.log("准备弹窗！");
                            that.data = []
                            await that.doSearch(that.page);
                            Modal.info({
                                title: "成功"
                            });
                        } else {
                            Modal.warning({
                                title: "错误信息：" + result.msg
                            });
                        }

                    }

                });


            },
            async delGroupOk(id) {
                var that = this;
                let result = [];// eslint-disable-line no-unused-vars
                Modal.confirm({
                    title: "警告！",
                    content: "是否确认剔除该权限组",
                    onOk: async function () {
                        console.log("执行完毕！");
                        let result = await that.$post("/api/admin/ruleCategory/delete", {
                            id: id,
                            token: Storage.token,
                            uid: Storage.uid,
                            // notes: this.edit_pop.notes,
                            t: Date.now(),
                            // sign: Crypt.sign([
                            //     Storage.token,
                            //     this.edit_pop.id,
                            //     JSON.stringify(data),
                            //     this.edit_pop.notes,
                            //     this.$config.secret_key,
                            //     now
                            // ])
                        });
                        console.log(result);
                        if (result.status == true) {
                            console.log("准备弹窗！");
                            that.data = []
                            await that.doSearch(that.page);
                            Modal.info({
                                title: "剔除成功"
                            });
                        } else {
                            Modal.warning({
                                title: "错误信息：" + result.msg
                            });
                        }

                    }

                });


            },


            async doSearch(page) {
                this.page = page;
                this.loading = true;
                let limit = 15;
                let pages = page;

                // console.log(userstatus);
                let uid = Storage.uid;
                let now = Date.now();
                let token = Storage.token;
                let result = await this.$get("/api/admin/ruleCategory/list", {
                    params: {
                        uid: uid,
                        token: token,
                        limit: limit,
                        page: pages,

                        // company_id: Storage.company_id,
                        // perm_permissions_group_id:perm_permissions_group_id,
                        t: now,
                    },
                    // sign: Crypt.sign([
                    //     uid,
                    //     token,
                    //     pages,
                    //     limit,
                    //     // this.$config.secret_key,
                    // ])
                });
                if (result.status === true) {
                    let data = [...this.data];
                    for (let i in result.data) {
                        let info = result.data[i];
                        let offset = (parseInt(page) - 1) * limit + parseInt(i);

                        data[offset] = {
                            id: info.id,
                            name: info.name,
                            rules: info.rules,
                        };
                    }

                    // let pagination = {...this.pagination};
                    // pagination.total = result.data.total;
                    // this.pagination = pagination;
                    this.data = data;
                    this.loading = false;
                }

                this.loading = false;
            },

        }
    };
</script>
<style scoped>
    .editable-row-operations a {
        margin-right: 8px;
    }

    #components-layout-demo-custom-trigger .trigger {
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;
    }

    #components-layout-demo-custom-trigger .trigger:hover {
        color: #1890ff;
    }

    #components-layout-demo-custom-trigger .logo {
        height: 32px;
        background: rgba(255, 255, 255, 0.2);
        margin: 16px;
    }

    .imgs {
        text-align: center;
        display: block;
    }

    .imgs img {
        height: 200px;
        width: auto;
    }

    .left_button {
        width: 50vw;
        float: left;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: left;
        margin-left: 25px;
        z-index: 1000;
    }

    .left_button button {
        margin-right: 20px;
    }

    .serach_input {
        width: 25vw;
        float: right;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: right;
        margin-right: 25px;
        z-index: 1000;
    }

    .pages {
        float: right;
        margin-top: 10px;
    }

    .toolsbar {
        display: block;
        width: 100%;
        height: 42px;
        background: #fff;
    }
    .ant-checkbox-group /deep/ .ant-checkbox-wrapper:first-child{margin-left: 8px}
</style>
